import React, { Fragment, useState, useEffect } from "react";
import { Table } from "../../components/Table";
import { PrimaryButton } from "../../components/Buttons/PrimaryButton";
import { Page } from "../../components/Page";
import { useAuth } from "../../hooks/auth";
import { useHistory } from "react-router";
import { request } from "../../services/request";
import { SecondaryButton } from "../../components/Buttons/SecondaryButton";


export const Fields: React.FC = () => {
  const history = useHistory();
  const { selectedClient } = useAuth();

  const lines = [{
    name: 'Preditivo',
    key: 'preditivo'
  },
  {
    name: 'Receptivo',
    key: 'receptivo'
  },
  {
    name: 'Preview',
    key: 'preview'
  },
  ]

  const columns = [
    {
      name: "name",
      label: "Nome",
    },
    {
      name: "key",
      label: "Ações",
      options: {
        filter: false,
        customBodyRender: (v: any) => {
          return (
            <Fragment>
              <SecondaryButton onClick={() => history.push(`campos/${v}/listar`)}>
                Campos
              </SecondaryButton>
              <SecondaryButton onClick={() => history.push(`campos/${v}/registros`)}>
                Registros
              </SecondaryButton>
            </Fragment>
          );
        },
      },
    },
  ]
  return (
    <Page header="Campos variáveis">
      <Fragment>
      </Fragment>

      <Fragment>
        <Table columns={columns} title="" data={lines}/>
      </Fragment>
    </Page>
  );
}
