import { Fragment, useState } from "react";
import { useParams } from "react-router";
import Select from "react-select";
import { NewButton } from "../../../components/Buttons/PrimaryButton/styles";
import { FormInputText } from "../../../components/FormInputs/Input";
import { FormArea } from "../../../components/FormInputs/styles";
import { Page } from "../../../components/Page";
import { request } from "../../../services/request";
import { toSeoUrl } from "../../../utils";
import sweetAlert from "../../../utils/sweetAlert";

export const NewQueue: React.FC<{ clickNewQueueOrBack: Function }> = ({
  clickNewQueueOrBack,
}) => {
  const [queue, setQueue] = useState<{ [key: string]: any }>({});
  const { clientId }: { clientId: string } = useParams();

  const handleSave = async () => {
    if (!queue.name) {
      sweetAlert.error("Nome é obrigatório");
      return;
    }

    let response = await request({
      loadingMessage: "Criando fila, aguarde...",
      method: "POST",
      path: `queues`,
      data: { ...queue, clientId },
    });

    if (!response.error) {
      clickNewQueueOrBack();
    }
  };

  const handleOnChangeInput = async (value: any, key: string) => {
    if (key === "login") {
      value = toSeoUrl(value);
    }

    setQueue((oldValue) => {
      return {
        ...oldValue,
        [key]: value,
      };
    });
  };

  return (
    <Page header="Filas > Nova">
      <Fragment></Fragment>
      <FormArea>
        <FormInputText
          label="Nome"
          name="name"
          onChangeFather={handleOnChangeInput}
          width="100%"
        />
        
        <Select
          label="Tipo de fila"
          name="type"
          onChange={(v) => handleOnChangeInput(v?.value, "type")}
          options={[
            { label: "Normal", value: "Normal" },
            { label: "Auditoria", value: "Auditoria" },
          ]}
          placeholder="Tipo"
        />
      </FormArea>

      <NewButton onClick={handleSave}>salvar</NewButton>
    </Page>
  );
};
