import React, { Fragment, useEffect, useState } from "react";
import { NewButton } from "../../../components/Buttons/PrimaryButton/styles";
import { FormInputText } from "../../../components/FormInputs/Input";
import Select from "react-select";
import { useAuth } from "../../../hooks/auth";
import { useHistory } from "react-router";
import { FormArea } from "../../../components/FormInputs/styles";
import { Page } from "../../../components/Page";
import { request } from "../../../services/request";
import { useParams } from "react-router-dom";


export const NewField: React.FC = () =>  {
  const history = useHistory();
  const [name, setName ] = useState('')
  const [type, setType ] = useState('')
  const { status }: { status: string } = useParams();
  const statusTypes = [ 'preditivo', 'receptivo', 'preview']
  const { selectedClient } = useAuth();

  const saveChanges = async () => {
    if (!name || !type) return null

    await request({
      method: 'POST',
      path: `fields/create-fields`,
      data: {
        type,
        name,
        clientId: selectedClient?.value,
        status,
      },
      showSuccessMessage: true,
      showErrorMessage: true
    });

    history.goBack()
  }

  useEffect(()=> {
    if (!statusTypes.includes(status)) {
      history.goBack()
    }
  }, [])

  return (
    <Page header="Campos variáveis > Novo">
      <Fragment></Fragment>
      <FormArea>

        <FormInputText
          label="Name"
          name="name"
          onChangeFather={(e: any) => {
            setName(e)
          }}
          width="100%"
          defaultValue={name}
        />
        <Select
          label="Tipo"
          name="type"
          onChange={(v: any) => {
            setType(v?.value)
          }}
          options={[
            { label: "Texto", value: 'text' },
            { label: "Numérico", value: 'number' },
            { label: "E-mail", value: 'email' },
            { label: "Telefone", value: 'tel' },
            { label: "Data e hora", value: 'datetime-local' },
            { label: "Data", value: 'datet' },
            { label: "Hora", value: 'time' },
          ]}
          placeholder="Tipo"
        />
      </FormArea>

      <NewButton onClick={saveChanges}>Salvar</NewButton>
    </Page>
  );
}
