import { Fragment, useEffect, useState } from "react";
import Select from "react-select";
import { PrimaryButton } from "../../components/Buttons/PrimaryButton";
import {
  SecondaryAreaButton,
  SecondaryButton,
} from "../../components/Buttons/SecondaryButton";
import { Page } from "../../components/Page";
import { Table } from "../../components/Table";
import { request } from "../../services/request";
import { CloseModal, ModalArea, ModalBody } from "./style";

interface IRamal {
  ramal: string;
  passwordInAsterisk: string;
  user: IUser | false;
}

interface IUser {
  _id: string;
  name: string;
  login: string;
  ramal: string;
}

export const Ramais: React.FC = () => {
  const [ramais, setRamais] = useState<Array<IRamal>>([]);
  const [users, setUsers] = useState<Array<IUser>>([]);
  const [userInRamal, setUserInRamal] = useState<string | null>(null);
  const [showAddEditUser, setShowAddEditUser] = useState<string | null>(null);
  const columns = [
    {
      name: "ramal",
      label: "Ramal",
    },
    {
      name: "user",
      label: "Usuário",
      options: {
        filter: false,
        customBodyRender: (v: any) => {
          if (!v) return "";
          return `${v.name} - ${v.login}`;
        },
      },
    },
    {
      name: "passwordInAsterisk",
      label: "Senha",
    },
    {
      name: "_id",
      label: "Ações",
      options: {
        filter: false,
        customBodyRender: (v: string, a: any) => {
          return (
            <SecondaryAreaButton>
              {!a.rowData[1] && (
                <SecondaryButton
                  onClick={() => {
                    setShowAddEditUser(v);
                  }}
                >
                  adicionar usuário
                </SecondaryButton>
              )}
              {a.rowData[1] && (
                <SecondaryButton
                  onClick={() => {
                    handleRemoveUserRamal(a.rowData[1]._id);
                  }}
                >
                  remover usuário
                </SecondaryButton>
              )}
            </SecondaryAreaButton>
          );
        },
      },
    },
  ];
  const getRamais = async () => {
    const response = await request({
      method: "GET",
      path: "ramais",
      showSuccessMessage: false,
      timeout: 99999,
    });

    if (!response.error) {
      setRamais(response.ramais);
      setUsers(response.users);
    }
  };

  const handleNewRamal = async () => {
    const response = await request({
      method: "post",
      path: "ramais",
    });

    if (!response.error) {
      getRamais();
    }
  };

  const handleAddEditUserRamal = async () => {
    const response = await request({
      method: "PUT",
      path: `users/${userInRamal}/ramal`,
      data: {
        ramal: showAddEditUser,
      },
    });

    if (!response.error) {
      setShowAddEditUser(null);
      setUserInRamal(null);
      getRamais();
    }
  };

  const handleRemoveUserRamal = async (user: string) => {
    const response = await request({
      method: "PUT",
      path: `users/${user}/ramal`,
      data: {
        ramal: null,
      },
    });

    if (!response.error) {
      setShowAddEditUser(null);
      setUserInRamal(null);
      getRamais();
    }
  };

  useEffect(() => {
    getRamais();
  }, []);

  return (
    <Page header="Ramais">
      <PrimaryButton text="+ novo ramal" onClick={handleNewRamal} />
      <Fragment>
        {showAddEditUser && (
          <ModalArea>
            <ModalBody>
              <CloseModal onClick={() => setShowAddEditUser(null)}>
                x
              </CloseModal>
              <Select
                options={users.map((u) => {
                  return {
                    value: u._id,
                    label: u.login,
                  };
                })}
                placeholder="Selecione um usuário"
                onChange={(v) => setUserInRamal(v ? v.value : null)}
              />
              <PrimaryButton text="salvar" onClick={handleAddEditUserRamal} />
            </ModalBody>
          </ModalArea>
        )}
        <Table columns={columns} title="" data={ramais} />
      </Fragment>
    </Page>
  );
};
