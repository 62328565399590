import React, { Fragment, useState } from "react";
import { NewButton } from "../../../../components/Buttons/PrimaryButton/styles";
import { FormInputText } from "../../../../components/FormInputs/Input";
import { useAuth } from "../../../../hooks/auth";
import { useHistory } from "react-router";
import { Page } from "../../../../components/Page";
import { request } from "../../../../services/request";
import { useParams } from "react-router-dom";


export const CreateFields: React.FC = () =>  {
  const history = useHistory();
  const [name, setName ] = useState('')
  const { campaignId }: { campaignId: string } = useParams();
  const { selectedClient } = useAuth();

  const saveChanges = async () => {
    if (!name) return null
    const response = await request({
      method: 'POST',
      path: `campaign/update-fields-campaign/${selectedClient?.value}?campaignId=${campaignId}`,
      data: {
        name
      },
      showSuccessMessage: true,
      showErrorMessage: true
    });

    if(!response.error) {
      history.goBack()
    }
  }

  return (
    <Page header="Campaigns > Campos editaveis > Novo">
      <Fragment></Fragment>
      <Fragment>
        <FormInputText
          label="Name"
          name="name"
          onChangeFather={(e: any) => {
            setName(e)
          }}
          width="100%"
          defaultValue={name}
        />
      </Fragment>

      <NewButton onClick={saveChanges}>Salvar</NewButton>
    </Page>
  );
}
