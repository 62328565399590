import styled from "styled-components";

export const QueueArea = styled.div`
  width: 100%;
  .MuiPaper-root {
    /* width: 45%; */
  }
`;

export const DataArea = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;

  span {
    width: 15%;
    min-width: 300px;
    padding: 20px;
    border: 1px solid #ccc;
    margin: 10px;
    border-radius: 6px;
    font-size: 14px;
  }
`;
