import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router";
import { NewButton } from "../../components/Buttons/PrimaryButton/styles";
import { Page } from "../../components/Page";
import { request } from "../../services/request";
import sweetAlert from "../../utils/sweetAlert";
import { GuestTexArea } from "./styles";

export const PreApproachTabbings: React.FC = () => {
  const [tabbings, setTabbings] = useState<string>("");
  const [clientName, setClientName] = useState<string>("");
  const { clientId } = useParams<{ clientId: string }>();

  const getTabbings = async () => {
    const response = await request({
      method: "GET",
      path: `enterprises/pre-approach-tabbing-status`,
      showSuccessMessage: false,
    });

    if (!response.error) {
      setTabbings(
        response
          .map((ts: { type: string; status: string }) => {
            return `${ts.status},${ts.type}`;
          })
          .join("\n")
      );
    }
  };

  const formatNames = (value: string) => {
    let valueInArray: Array<string> = value.split("\n");
    valueInArray = valueInArray.filter((v) => v !== "");

    let values = valueInArray.map((v) => {
      return { value: v.split(",")[0], type: v.split(",")[1] };
    });

    let withoutDuplicates: Array<string> = [];

    values.forEach((v, i) => {
      if (!v) return;
      let alreadyExist = false;
      values.forEach((vAe, index) => {
        if (vAe.value === v.value && i !== index) {
          alreadyExist = true;
        }

        if (vAe.value === v.value && i === index) {
          alreadyExist = false;
        }
      });

      if (!alreadyExist) {
        withoutDuplicates.push(`${v.value},${v.type}`);
      }
    });

    return { values: withoutDuplicates, success: true };
  };

  const handleChangeTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    let value = e.target.value;
    setTabbings(value);

    let values = formatNames(value);

    if (!values.success) {
      sweetAlert.error(
        `Nomes duplicados, remova-os: \n ${values.values.join("\n")}`
      );
    }
  };

  const handleSave = async () => {
    let values = formatNames(tabbings);

    if (!values.success) {
      sweetAlert.error(
        `Nomes duplicados, remova-os: \n ${values.values.join("\n")}`
      );
      return;
    }

    await request({
      method: "PUT",
      path: `enterprises/pre-approach-tabbing-status`,
      data: { tabbingsStatus: values.values },
    });
  };

  useEffect(() => {
    getTabbings();
  }, []); //eslint-disable-line

  return (
    <Page header={`Clientes > ${clientName} > Tabulações`}>
      <Fragment></Fragment>
      <Fragment>
        <p>
          Status de tabulação devem ser separados por enter e o tipo por ",".
        </p>
        <br></br>
        <p>
          fl = Finalizador do lead <br></br>
          ft = Finalizador do telefone <br></br>
          ag-base = Agendamento base<br></br>
          ag-pessoal = Agendamento pessoal <br></br>
          tel = Telefonia <br></br>
          rediscar = Aparece o botão de rediscar e cria uma nova ligação{" "}
          <br></br>
        </p>
        <br></br>
        <p>Exemplo: </p>
        <p>contato não existe,fl</p>
        <p>telefone não existe,ft</p>
        <GuestTexArea onChange={handleChangeTextArea} value={tabbings} />
      </Fragment>
      <NewButton onClick={handleSave}>salvar</NewButton>
    </Page>
  );
};
