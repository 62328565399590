import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router";
import { NewButton } from "../../../components/Buttons/PrimaryButton/styles";
import { FormInputText } from "../../../components/FormInputs/Input";
import { Select } from "../../../components/FormInputs/Select";
import { FormArea } from "../../../components/FormInputs/styles";
import { Page } from "../../../components/Page";
import { request } from "../../../services/request";
import { toSeoUrl } from "../../../utils";

// interface IRamal {
//   ramal: string;
//   passwordInAsterisk: string;
//   user: IUser | false;
// }

// interface IUser {
//   _id: string;
//   name: string;
//   login: string;
//   ramal: string;
// }

export const EditUser: React.FC = () => {
  const [user, setUser] = useState<{ [key: string]: any }>({});
  const [oldUser, setOldUser] = useState<{ [key: string]: any }>({});
  const [userTypes, setUserTypes] = useState<
    Array<{ name: string; _id: string }>
  >([]);
  const { userId }: { userId: string } = useParams();
  // const [ramais, setRamais] = useState<Array<IRamal>>([]);

  // const getRamais = async () => {
  //   const response = await request({
  //     method: "GET",
  //     path: "ramais",
  //     showSuccessMessage: false,
  //     timeout: 99999,
  //   });

  //   if (!response.error) {
  //     setRamais(response.ramais);
  //   }
  // };

  const handleSave = async () => {
    await request({
      loadingMessage: "Atualizando usuário",
      method: "PUT",
      timeout: 9999,
      path: `users/${userId}`,
      data: user,
    });
  };

  const handleOnChangeInput = async (value: any, key: string) => {
    if (key === "login") {
      value = toSeoUrl(value);
    }

    if (value === "true") value = true;
    if (value === "false") value = false;

    setUser((ou) => {
      return {
        ...ou,
        [key]: typeof value === "string" ? value.trim() : value,
      };
    });
  };

  const getUserTypes = async () => {
    const res = await request({
      method: "GET",
      path: `users/types`,
      showSuccessMessage: false,
    });

    if (!res.error) {
      setUserTypes(res);
    }
  };

  const getUser = async () => {
    const res = await request({
      method: "GET",
      path: `users/${userId}`,
      showSuccessMessage: false,
    });

    if (!res.error) {
      await getUserTypes();
      setOldUser(res);
    }
  };

  useEffect(() => {
    getUser();
  }, []); //eslint-disable-line

  return (
    <Page header="Usuários > Editar">
      <Fragment></Fragment>
      <FormArea>
        <FormInputText
          label="Nome"
          name="name"
          onChangeFather={handleOnChangeInput}
          defaultValue={user.name ? user.name : oldUser.name}
        />
        <FormInputText
          disabled
          label="Login"
          name="login"
          onChangeFather={handleOnChangeInput}
          defaultValue={user.login ? user.login : oldUser.login}
        />
        {oldUser.userTypeId && (
          <Select
            label="Permissão"
            name="userTypeId"
            onChangeFather={handleOnChangeInput}
            defaultValue={
              user.userTypeId ? user.userTypeId : oldUser.userTypeId
            }
            options={userTypes.map((u) => {
              return {
                name: u.name,
                value: u._id,
              };
            })}
          />
        )}

        {oldUser.userTypeId && (
          <Select
            label="Ativo"
            name="active"
            onChangeFather={handleOnChangeInput}
            defaultValue={user.softphoneWeb ? user.active : oldUser.active}
            options={[
              { name: "Sim", value: "true" },
              { name: "Não", value: "false" },
            ]}
          />
        )}

        {/* <Select
          options={ramais
            .filter((r) => !r.user)
            .map((r) => {
              return {
                value: r.ramal,
                label: r.ramal,
              };
            })}
          placeholder="Selecione um ramal"
          onChangeFather={(v) => handleOnChangeInput(v?.value, "ramal")}
        /> */}
        <Select
          label="Ressetar a senha"
          name="ressetPass"
          onChangeFather={handleOnChangeInput}
          defaultValue="false"
          options={[
            { name: "Sim", value: "true" },
            { name: "Não", value: "false" },
          ]}
        />
      </FormArea>

      <NewButton onClick={handleSave}>salvar</NewButton>
    </Page>
  );
};
