import React, { Fragment, useEffect, useState } from "react";
import { NewButton } from "../../../components/Buttons/PrimaryButton/styles";
import { FormInputText } from "../../../components/FormInputs/Input";
import Select from "react-select";
import CreateatableSelect from "react-select/creatable";
import { useHistory } from "react-router";
import { FormArea } from "../../../components/FormInputs/styles";
import { Page } from "../../../components/Page";
import { request } from "../../../services/request";
import { useParams } from "react-router-dom";


export const EditCampain: React.FC = () =>  {
  const history = useHistory();
  const [name, setName] = useState<string>('');
  const [queue, setQueue] = useState<string>('');
  const { campaignId }: { campaignId: string } = useParams();
  const [weight, setWeight] = useState<string>('');
  const [active, setActive] = useState<boolean>(true);

  const getCampaign = async () => {
    const data = await request({
      method: 'GET',
      path: `campaign/${campaignId}`,
      showSuccessMessage: false,
      showErrorMessage: true
    });

    setWeight(data?.weight)
    setActive(data?.active)
    setName(data?.name)
    setQueue(data?.queueId?.name)
  }

  const saveChanges = async () => {
    await request({
      method: 'PUT',
      path: `campaign/update-campaign/${campaignId}`,
      data: {
        weight,
        active
      },
      showSuccessMessage: false,
      showErrorMessage: true
    });

    history.goBack()
  }

  useEffect(()=> {
    getCampaign()
  }, [])

  return (
    <Page header="Campanhas > Editar">
      <Fragment></Fragment>
      <FormArea>

        <FormInputText
          label="Nome"
          name="name"
          onChangeFather={(e: any) => {}}
          width="100%"
          defaultValue={name}
          disabled
        />
        <FormInputText
          label="Fila"
          name="queue"
          onChangeFather={(e: any) => {}}
          width="100%"
          defaultValue={queue}
          disabled
        />
        <FormInputText
          label="Peso"
          name="Weight"
          onChangeFather={(e: any) => setWeight(e)}
          width="100%"
          defaultValue={weight}
        />
        <Select
          label="Ativo"
          name="active"
          onChange={(v: any) => {
            setActive(v?.value)
          }}
          options={[
            { label: "Sim", value: true },
            { label: "Não", value: false },
          ]}
          placeholder="Ativo"
        />
      </FormArea>

      <NewButton onClick={saveChanges}>Salvar</NewButton>
    </Page>
  );
}
