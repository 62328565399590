import { Fragment, useEffect, useState } from "react";
import { PrimaryButton } from '../../../components/Buttons/PrimaryButton';
import { SecondaryButton } from "../../../components/Buttons/SecondaryButton";
import { Wrapper, InputWrapper } from "./styles";
import { Page } from "../../../components/Page";
import { request } from "../../../services/request";
import { useAuth } from "../../../hooks/auth";
import { useParams } from "react-router-dom";

interface IFieldsData {
  data: any;
  _id: string;
  name: string;
  value: string;
  type: string;
}

export const RecordsData: React.FC = () => {
  const { selectedClient } = useAuth();
  const { recordId }: { recordId: string } = useParams();
  const [records, setRecords] = useState<Array<IFieldsData>>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [updatedRecords, setUpdatedRecords] = useState<Array<IFieldsData>>([]);

  const getFieldsData = async () => {
    if(!selectedClient) return;

    const response = await request({
      method: "GET",
      path: `fields/get-fields-data/${selectedClient?.value}?dataId=${recordId}`,
      showSuccessMessage: false,
    });
    if (!response.error) {
      setRecords(
        response.data.map((f: IFieldsData) => {
          return {
            name: f.name,
            value: f.value,
            type: f.type,
          };
        })
      );
    }
  };

  const handleSave = async () => {
    setIsEditing(false);
    const updatedData = records.map((item, index) => {
      return {
        ...item,
        value: updatedRecords[index].value,
      };
    });

    const response = await request({
      method: "PUT",
      path: `fields/update-fields-data/${selectedClient?.value}?dataId=${recordId}`,
      data: updatedData,
      showSuccessMessage: true,
    });
    if (!response.error) {
      setRecords(updatedData);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setUpdatedRecords(prevState =>
      prevState.map(item => {
        if (item.name === name) {
          return { ...item, value };
        }
        return item;
      })
    );
  };

  useEffect(() => {
    getFieldsData();
  }, [selectedClient]);

  useEffect(() => {
    setUpdatedRecords([...records])
  }, [records]);

  return (
    <Page header="Dados">
      <Fragment>
        {isEditing ? (
          <PrimaryButton text="Salvar" onClick={handleSave}/>
        ) : (
          <PrimaryButton text="Editar" onClick={() => setIsEditing(true)}/>
        )}
      </Fragment>
      <Fragment>
        <Wrapper>
        {records.map(item => (
          <InputWrapper key={recordId}>
            <label htmlFor={item.name}>{item.name}</label>
            {isEditing ? (
              <input
                type={item.type}
                id={item.name}
                name={item.name}
                value={ updatedRecords.find(record => record.name === item.name)?.value ?? item.value}
                onChange={handleChange}
              />
            ) : (
              <p>{item.value}</p>
            )}
          </InputWrapper>
        ))}
        </Wrapper>
      </Fragment>
    </Page>
  );
};
