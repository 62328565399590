import React, { Fragment, useState, useEffect } from "react";
import { Table } from "../../components/Table";
import { PrimaryButton } from "../../components/Buttons/PrimaryButton";
import { Page } from "../../components/Page";
import { useAuth } from "../../hooks/auth";
import { useHistory } from "react-router";
import { request } from "../../services/request";
import { SecondaryButton } from "../../components/Buttons/SecondaryButton";

interface ICampaign {
  name: string;
  clientId: string;
  queueId: any
  group?: {key: string};
  type: string;
  createdAt: Date | string;
  dateInit: Date | string;
  dateEnd: Date | string;
  active: boolean | string;
}

export const Campaigns: React.FC = () => {
  const history = useHistory();
  const { selectedClient } = useAuth();
  const [campaigns, setCampaigns] = useState<ICampaign[]>([])

  const handleNewCampaign = () => {
    history.push("/campaigns/novo");
  };


  const columns = [
    {
      name: "name",
      label: "Nome",
    },
    {
      name: "active",
      label: "Ativo",
    },
    {
      name: "dateInit",
      label: "Data de início",
    },
    {
      name: "dateEnd",
      label: "Data do Fim",
    },
    {
      name: "queueId",
      label: "Fila",
    },
    {
      name: "groupName",
      label: "Grupo",
    },
    {
      name: "type",
      label: "Tipo",
    },
    {
      name: "weight",
      label: "Peso",
    },
    {
      name: "_id",
      label: "Ações",
      options: {
        filter: false,
        customBodyRender: (v: any) => {
          return (
            <Fragment>
              <SecondaryButton onClick={() => history.push(`campaigns/${v}/campos-editaveis`)}>
                campos
              </SecondaryButton>
              <SecondaryButton onClick={() => history.push(`campaigns/${v}/editar`)}>
                editar
              </SecondaryButton>
            </Fragment>
          );
        },
      },
    },
  ]

  const getCampaigns = async () => {
   try {
    const response: ICampaign[] = await request({
      method: 'GET',
      path: `campaign/get-all-campaign/${selectedClient?.value}`,
      showSuccessMessage: false,
      showErrorMessage: false
    })
    const data = response.map((c: ICampaign) => {
      return {
        ...c,
        active: c.active? 'Sim' : 'Não',
        queueId: c.queueId?.name,
        groupName: c.group?.key
      }
    })
    setCampaigns(data)
    } catch(e) {
      console.log(e)
    }
  }

  useEffect(()=> {
    getCampaigns()
  }, [selectedClient])
  return (
    <Page header="Campanhas">
      <Fragment>
        <PrimaryButton text="+ nova campanha" onClick={handleNewCampaign} />
        <PrimaryButton text="Grupos de campanhas" onClick={() => history.push('campaigns/grupos')} />
      </Fragment>
      <Fragment>
        <Table columns={columns} title="" data={campaigns}/>
      </Fragment>
    </Page>
  );
}
