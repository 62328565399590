import React, { Fragment, useState, useEffect } from "react";
import { Table } from "../../components/Table";
import { PrimaryButton } from "../../components/Buttons/PrimaryButton";
import { Page } from "../../components/Page";
import { useHistory } from "react-router";
import { request } from "../../services/request";
import { SecondaryButton } from "../../components/Buttons/SecondaryButton";
import { useAuth } from "../../hooks/auth";


export const Scheduling: React.FC = () => {
  const history = useHistory();
  const { selectedClient } = useAuth();
  const [sheduling, setsheduling] = useState<any>([])

  const columns = [
    {
      name: "name",
      label: "Atendente",
    },
    {
      name: "date",
      label: "Data",
    },

    {
      name: "_id",
      label: "Ações",
      options: {
        filter: false,
        customBodyRender: (v: any) => {
          return (
            <Fragment>
              <SecondaryButton onClick={() => history.push(`agendamentos/${v}/editar`)}>
                editar
              </SecondaryButton>
              <SecondaryButton onClick={() => removeSchedule(v)}>
                cancelar
              </SecondaryButton>
            </Fragment>
          );
        },
      },
    },
  ]

  const removeSchedule = async (id: any) => {
    try {
      await request({
        method: 'DELETE',
        path: `schedules/cancel-schedule/${id}`,
        data: {
          type: 'DELETE'
        },
        showSuccessMessage: true,
        showErrorMessage: true
      })
      getSchedules();
    } catch {
      
    }
  }

  const getSchedules = async () => {
    try {
     const response = await request({
       method: 'GET',
       path: `schedules/get-all-schedule/${selectedClient?.value}`,
       showSuccessMessage: false,
       showErrorMessage: false
     })
     const data = response.map((s: any) => {
      const d = new Date(s.scheduleDate)
      const dateFormated = `${d.getDate() + 1}/${d.getMonth() + 1}/${d.getFullYear()}`
      return {
        ...s,
        name: s.userId?.name,
        date: `${dateFormated} ás ${s.scheduleHour}`,
      }
    })
     setsheduling(data)
     } catch(e) {
       console.log(e)
     }
   }

  useEffect(()=> {
    getSchedules()
  }, [selectedClient])
  return (
    <Page header="Agendamentos">
      <Fragment></Fragment>
      <Fragment>
        <Table columns={columns} title="" data={sheduling}/>
      </Fragment>
    </Page>
  );
}
