import React from "react";
import { Route, Switch } from "react-router-dom";
import { Clients } from "../pages/Clients";
import { EditClient } from "../pages/Clients/Edit";
import { NewClient } from "../pages/Clients/New";
import { Tabbings } from "../pages/Clients/Tabbings";
import { Dashboard } from "../pages/Dashboard";
import { Login } from "../pages/Login";
import { Pauses } from "../pages/Pauses";
import { PreApproachTabbings } from "../pages/PreApproachTabbing";
import { Queues } from "../pages/Queues";
import { Leads } from "../pages/Queues/Leads";
import { AddRemoveUsersInQueue } from "../pages/Queues/Users";
import { Ramais } from "../pages/Ramais";
import { Users } from "../pages/Users";
import { EditUser } from "../pages/Users/Edit";
import { NewUser } from "../pages/Users/New";
import { Campaigns } from '../pages/Campains';
import { NewCampain } from '../pages/Campains/New';
import { EditCampain } from '../pages/Campains/Edit';
import { PrivateRoute } from "./PrivateRoute";
import { Scheduling  } from "../pages/Scheduling";
import { EditScheduling } from "../pages/Scheduling/Edit";
import { CampaignsGroup } from "../pages/Campains/Groups";
import { EditGroupCampaign } from "../pages/Campains/EditGroups";
import { EditableFields } from "../pages/Campains/EditableFields";
import { Fields } from "../pages/Fields";
import { ListFields } from "../pages/Fields/ListFields";
import { NewField } from "../pages/Fields/New";
import { EditField } from "../pages/Fields/Edit";
import { Records } from "../pages/Fields/Records";
import { RecordsData } from "../pages/Fields/RecordsData";
import { CreateFields } from "../pages/Campains/EditableFields/CreateFields";

export const Routes: React.FC = () => (
  <Switch>
    <Route path="/login" exact component={Login} />
    <PrivateRoute path="/" exact component={Dashboard} />
    <PrivateRoute path="/usuarios" exact component={Users} />
    <PrivateRoute path="/clientes" exact component={Clients} />
    <PrivateRoute
      path="/clientes/:clientId/tabulacoes"
      exact
      component={Tabbings}
    />
    <PrivateRoute path="/clientes/:clientId/filas" exact component={Queues} />
    <PrivateRoute
      path="/clientes/:clientId/filas/:queueId/adicionar-remover-usuarios"
      exact
      component={AddRemoveUsersInQueue}
    />
    <PrivateRoute
      path="/clientes/:clientId/filas/:queueId/leads"
      exact
      component={Leads}
    />
    <PrivateRoute
      path="/clientes/:clientId/editar"
      exact
      component={EditClient}
    />
    <PrivateRoute path="/usuarios/novo" exact component={NewUser} />
    <PrivateRoute path="/usuarios/:userId/editar" exact component={EditUser} />
    <PrivateRoute path="/clientes/novo" exact component={NewClient} />
    <PrivateRoute path="/pausas" exact component={Pauses} />
    <PrivateRoute path="/campaigns" exact component={Campaigns} />
    <PrivateRoute path="/campaigns/novo" exact component={NewCampain} />
    <PrivateRoute path="/campaigns/:campaignId/editar" exact component={EditCampain} />
    <PrivateRoute path="/campaigns/grupos" exact component={CampaignsGroup} />
    <PrivateRoute path="/campaigns/grupos/:groupId/editar" exact component={EditGroupCampaign} />
    <PrivateRoute path="/campaigns/:campaignId/campos-editaveis" exact component={EditableFields} />
    <PrivateRoute path="/campaigns/:campaignId/campos-editaveis/novo" exact component={CreateFields} />
    <PrivateRoute path="/agendamentos" exact component={Scheduling} />
    <PrivateRoute path="/agendamentos/:scheduleId/editar" exact component={EditScheduling} />
    <PrivateRoute path="/campos" exact component={Fields} />
    <PrivateRoute path="/campos/editar" component={EditField} exact={false} />
    <PrivateRoute path="/campos/:status/novo" exact component={NewField} />
    <PrivateRoute path="/campos/:status/listar" exact component={ListFields} />
    <PrivateRoute path="/campos/:status/registros" exact component={Records} />
    <PrivateRoute path="/campos/:status/registros/:recordId/dados-preenchidos" exact component={RecordsData} />
    <PrivateRoute path="/ramais" exact component={Ramais} />{" "}

    <PrivateRoute
      path="/tabulacoes-pre-abordagem"
      exact
      component={PreApproachTabbings}
    />
  </Switch>
);
