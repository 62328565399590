import styled from "styled-components";
import { DefaultColors } from "../../defaultColors";

export const InputDate = styled.input`
::-webkit-datetime-edit { padding: 10px 30px; }
::-webkit-datetime-edit-text { color: black; padding: 0 0.3em; }
::-webkit-inner-spin-button { display: none; }
::-webkit-calendar-picker-indicator { margin-right: 10px }
border: 1px solid #ccc;
border-radius: 3px;
`;

export const LabelDate = styled.label`
  margin-right: 20px;
  padding: 0;
`;

export const BoxDate = styled.div`
  margin: 0;
  padding: 0;
`;

