import { Fragment, useEffect, useState } from "react";
import Select from "react-select";
import { NewButton } from "../../../components/Buttons/PrimaryButton/styles";
import { FormInputText } from "../../../components/FormInputs/Input";
import { FormArea } from "../../../components/FormInputs/styles";
import { Page } from "../../../components/Page";
import { request } from "../../../services/request";
import { toSeoUrl } from "../../../utils";
import sweetAlert from "../../../utils/sweetAlert";

export const NewUser: React.FC = () => {
  const [user, setUser] = useState<{ [key: string]: any }>({});
  const [userTypes, setUserTypes] = useState<
    Array<{ name: string; _id: string }>
  >([]);

  const handleSave = async () => {
    if (!user.name) {
      sweetAlert.error("Nome é obrigatório");
      return;
    }

    if (!user.login) {
      sweetAlert.error("Login é obrigatório");
      return;
    }

    if (!user.userTypeId) {
      sweetAlert.error("Permissão  é obrigatório");
      return;
    }

    await request({
      loadingMessage: "Criando usuário, aguarde...",
      method: "POST",
      path: `users`,
      timeout: 9999999,
      data: user,
    });
  };

  const handleOnChangeInput = async (value: any, key: string) => {
    if (key === "login") {
      value = toSeoUrl(value);
    }

    if (key === "name") {
      let nameSplit = value.split(" ");
      let nameLastName = `${nameSplit[0]} ${nameSplit[nameSplit.length - 1]}`;
      handleOnChangeInput(nameLastName, "login");
    }

    value = value === "true" ? true : value;
    value = value === "false" ? false : value;

    setUser((oldUser) => {
      return {
        ...oldUser,
        [key]: value,
      };
    });
  };

  const getUserTypes = async () => {
    const res = await request({
      method: "GET",
      path: `users/types`,
      showSuccessMessage: false,
    });

    if (!res.error) {
      setUserTypes(res);
    }
  };

  useEffect(() => {
    getUserTypes();
  }, []); //eslint-disable-line

  return (
    <Page header="Usuários > Novo">
      <Fragment></Fragment>
      <FormArea>
        <FormInputText
          label="Nome"
          name="name"
          onChangeFather={handleOnChangeInput}
        />
        <FormInputText
          label="Login"
          name="login"
          onChangeFather={handleOnChangeInput}
          defaultValue={user.login ? user.login : ""}
        />

        <Select
          label="Atribuir ramal"
          name="softphoneWeb"
          onChange={(v) => handleOnChangeInput(v?.value, "setRamal")}
          options={[
            { label: "Sim", value: "true" },
            { label: "Não", value: "false" },
          ]}
          placeholder="Atribuir ramal"
        />
        <Select
          maxMenuHeight={250}
          placeholder="Selecione uma permissão"
          name="userTypeId"
          onChange={(v) => handleOnChangeInput(v?.value, "userTypeId")}
          options={userTypes.map((u) => {
            return {
              label: u.name,
              value: u._id,
            };
          })}
        />
      </FormArea>

      <NewButton onClick={handleSave}>salvar</NewButton>
    </Page>
  );
};
