import { useState } from "react";
import { useHistory } from "react-router";
import PessoalizeLogo from "../../assets/images/pessoalizeLoginLogo.png";
import { useAuth } from "../../hooks/auth";
import { setLsUserToken } from "../../localStorage/index";
import { request } from "../../services/request";
import {
  AreaButtons,
  LoginContainer,
  LoginContent,
  LoginHeader,
} from "./styles";

export const Login: React.FC = () => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const { verifyUserExists } = useAuth();
  const history = useHistory();
  const handleLogin = async () => {
    const response = await request({
      showLoading: true,
      showSuccessMessage: false,
      method: "POST",
      path: `users/login`,
      data: {
        login: userName,
        password: password,
      },
    });
    if (response.access_token) {
      setLsUserToken(response.access_token);
      await verifyUserExists();
      history.push("/");
    }
  };

  const handleFirstAcess = async () => {
    const response = await request({
      showLoading: true,
      showSuccessMessage: false,
      method: "POST",
      path: `users/login`,
      data: {
        login: `${userName}`,
        password: password,
        firstAccess: true,
      },
    });

    if (response.access_token) {
      setLsUserToken(response.access_token);
      await verifyUserExists();
      history.push("/");
    }
  };

  return (
    <LoginContainer>
      <LoginContent>
        <LoginHeader>
          <img src={PessoalizeLogo} alt="Pessoalize Logo" />
          <h3>Login</h3>
        </LoginHeader>

        <input
          placeholder="Digite seu usuário"
          type="text"
          onChange={(e) => {
            setUserName(e.target.value);
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handleLogin();
            }
          }}
        />
        <input
          placeholder="Digite sua senha"
          type="password"
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handleLogin();
            }
          }}
        />
        <AreaButtons>
          <button
            onClick={handleLogin}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleLogin();
              }
            }}
          >
            Entrar
          </button>
          <button
            onClick={handleFirstAcess}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleFirstAcess();
              }
            }}
          >
            Primeiro acesso / Nova senha
          </button>
        </AreaButtons>
      </LoginContent>
    </LoginContainer>
  );
};
