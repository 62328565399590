import React, { Fragment, useEffect, useState } from "react";
import { NewButton } from "../../../components/Buttons/PrimaryButton/styles";
import { FormInputText } from "../../../components/FormInputs/Input";
import Select from "react-select";
import { useAuth } from "../../../hooks/auth";
import { useHistory } from "react-router";
import { FormArea } from "../../../components/FormInputs/styles";
import { Page } from "../../../components/Page";
import { request } from "../../../services/request";
import { useParams, useLocation } from "react-router-dom";


function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}


export const EditField: React.FC = () =>  {
  const history = useHistory();
  const query = useQuery()
  const [name, setName ] = useState<any>('')
  const [type, setType ] = useState<any>('')

  const { selectedClient } = useAuth();


  const saveChanges = async () => {
    if (!name || !type) return null

    await request({
      method: 'PUT',
      path: `fields/update-fields`,
      data: {
        type,
        newName: name,
        oldName: query.get('name'),
        status: query.get('status'),
        clientId: selectedClient?.value,
      },
      showSuccessMessage: true,
      showErrorMessage: true
    });

    history.goBack()
  }

  useEffect(()=> {
    const name = query.get('name')
    setName(name)
  }, [])

  return (
    <Page header="Campos variáveis > Novo">
      <Fragment></Fragment>
      <FormArea>

        <FormInputText
          label="Name"
          name="name"
          onChangeFather={(e: any) => {
            setName(e)
          }}
          width="100%"
          defaultValue={name}
        />
        <Select
          label="Tipo"
          name="type"
          onChange={(v: any) => {
            setType(v?.value)
          }}
          options={[
            { label: "Texto", value: 'text' },
          ]}
          placeholder="Tipo"
        />
      </FormArea>

      <NewButton onClick={saveChanges}>Salvar</NewButton>
    </Page>
  );
}
