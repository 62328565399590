import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import { Table } from "../../components/Table";
import { request } from "../../services/request";
import { sleep } from "../../utils";
import { QueueArea } from "./style";

function convert(value: number) {
  return Math.floor(value / 60) + ":" + (value % 60 ? value % 60 : "00");
}

export const Dashboard: React.FC = () => {
  const [queues, setQueues] = useState<any>([]);
  const [expandableClientRows, setExpandableClientRows] = useState<
    Array<{ row: number }>
  >([]);
  const [expandableQueueRows, setExpandableQueueRows] = useState<
    Array<{ row: number }>
  >([]);
  const [pauseInterval, setPauseInterval] = useState<boolean>(false);
  const attendantCollumns = [
    {
      name: "sip",
      label: "SIP",
    },
    {
      name: "name",
      label: "Nome",
    },
    {
      name: "login",
      label: "Login",
    },
    {
      name: "status",
      label: "Status",
    },
    {
      name: "tempo",
      label: "Tempo",
    },
    {
      name: "atendidas",
      label: "Atendidas",
    },
    {
      name: "percentual",
      label: "Percentual",
    },
    {
      name: "ultima",
      label: "Ultima",
    },
  ];

  const queuesCollumns = [
    {
      name: "name",
      label: "Fila",
    },
    {
      name: "abandonadas",
      label: "Ligações abandonadas",
    },
    {
      name: "agressividade",
      label: "Agressividade",
    },

    {
      name: "atendidas",
      label: "Ligações atendidas",
    },
    {
      name: "atendimento",
      label: "Ligações em andamento",
    },
    {
      name: "em_pausa",
      label: "Atendentes em pausa",
    },
    {
      name: "leads_imediatos",
      label: "Leads para discagem imediata",
    },
    {
      name: "leads_totais",
      label: "Total de leads",
    },
    {
      name: "numeros_restantes",
      label: "Números restantes",
    },
    {
      name: "offline",
      label: "MWT",
      options: {
        filter: false,
        customBodyRender: (v: string, row: any) => {
          let attendants = row.tableData[row.rowIndex].attendants.filter(
            (a: any) => a.status === "AGUARDANDO"
          );
          let mwt = 0;
          attendants.forEach((a: any) => {
            let time = a.tempo;
            let timeSplit = time.split(":");
            let minutes = parseInt(timeSplit[1]) * 60;
            let seconds = parseInt(timeSplit[2]);
            let totalInSeconds = minutes + seconds;
            if (totalInSeconds > mwt) {
              mwt = totalInSeconds;
            }
          });
          return convert(mwt);
        },
      },
    },
    {
      name: "tma",
      label: "TME",
    },
    {
      name: "tme",
      label: "TMA",
    },
    {
      name: "aguardando",
      label: "Clientes em espera",
    },
    {
      name: "offline",
      label: "Atententes offline",
    },
  ];

  const clientCollums = [
    {
      name: "clientName",
      label: "Cliente",
    },
    {
      name: "porcentagem_abandono",
      label: "% Abandono",
    },
    {
      name: "abandonadas",
      label: "Ligações abandonadas",
    },
    {
      name: "atendidas",
      label: "Ligações atendidas",
    },
    {
      name: "atendimento",
      label: "Ligações em andamento",
    },
    {
      name: "em_pausa",
      label: "Atendentes em pausa",
    },
    {
      name: "leads_imediatos",
      label: "Leads para discagem imediata",
    },
    {
      name: "leads_totais",
      label: "Total de leads",
    },
    {
      name: "numeros_restantes",
      label: "Números restantes",
    },
  ];

  const getQueues = async () => {
    if (pauseInterval) return;
    const response = await request({
      removeIp: true,
      method: "GET",
      path: "webhook/status-central",
      showSuccessMessage: false,
      showErrorMessage: false,
      showLoading: false,
      timeout: 99999,
    });

    if (!response.error) {
      let clients: any = [];
      response.forEach((q: any) => {
        let alreadyExistClient = clients.findIndex(
          (c: any) => c.clientId === q.client._id
        );

        if (alreadyExistClient < 0) {
          clients.push({
            clientName: q.client.name,
            clientId: q.client._id,
            queues: [q],
          });
        } else {
          clients[alreadyExistClient].queues.push({ ...q });
        }
      });

      clients = clients.map((c: any) => {
        let clientResume = {
          abandonadas: 0,
          atendidas: 0,
          atendimento: 0,
          em_pausa: 0,
          leads_imediatos: 0,
          leads_totais: 0,
          numeros_restantes: 0,
          porcentagem_abandono: "",
        };

        c.queues.forEach((q: any) => {
          q.abandonadas = parseInt(q.abandonadas);
          q.atendidas = parseInt(q.atendidas);
          q.atendimento = parseInt(q.atendimento);
          q.em_pausa = parseInt(q.em_pausa);
          q.leads_imediatos = parseInt(q.leads_imediatos);
          q.leads_totais = parseInt(q.leads_totais);
          q.numeros_restantes = parseInt(q.numeros_restantes);

          clientResume.abandonadas = clientResume.abandonadas + q.abandonadas;
          clientResume.atendidas = clientResume.atendidas + q.atendidas;
          clientResume.atendimento = clientResume.atendimento + q.atendimento;
          clientResume.em_pausa = clientResume.em_pausa + q.em_pausa;
          clientResume.leads_imediatos =
            clientResume.leads_imediatos + q.leads_imediatos;
          clientResume.leads_totais =
            clientResume.leads_totais + q.leads_totais;
          clientResume.numeros_restantes =
            clientResume.numeros_restantes + q.numeros_restantes;
        });

        clientResume.porcentagem_abandono =
          (
            (clientResume.atendidas + clientResume.abandonadas) /
            clientResume.atendidas
          ).toFixed(2) + "%";

        return {
          ...c,
          ...clientResume,
        };
      });

      console.log(clients);
      setQueues(clients);
    }

    await sleep(1000);
    getQueues();
  };

  useEffect(() => {
    getQueues();
    setPauseInterval(false);
    return () => {
      setPauseInterval(true);
    };
  }, []); //eslint-disable-line

  return (
    <QueueArea>
      <Table
        columns={clientCollums}
        title=""
        data={queues}
        moreOptions={{
          expandableRows: true,
          expandableRowsHeader: false,
          expandableRowsOnClick: true,
          serverSide: true,
          rowsExpanded: expandableClientRows.map((v) => v.row),
          renderExpandableRow: (rowData: any, rowMeta: any) => {
            const colSpan = rowData.length + 1;

            return (
              <TableRow>
                <TableCell colSpan={colSpan}>
                  <Table
                    columns={queuesCollumns}
                    title=""
                    data={queues[rowMeta.rowIndex].queues}
                    moreOptions={{
                      expandableRows: true,
                      expandableRowsHeader: false,
                      expandableRowsOnClick: true,
                      serverSide: true,
                      rowsExpanded: expandableQueueRows.map((v) => v.row),
                      renderExpandableRow: (
                        rowDataQueue: any,
                        rowMetaQueue: any
                      ) => {
                        const colSpanQueue = rowDataQueue.length + 1;

                        return (
                          <TableRow>
                            <TableCell colSpan={colSpanQueue}>
                              <Table
                                columns={attendantCollumns}
                                title=""
                                data={
                                  queues[rowMeta.rowIndex].queues[
                                    rowMetaQueue.rowIndex
                                  ].attendants
                                }
                              />
                            </TableCell>
                          </TableRow>
                        );
                      },
                      onRowExpansionChange: (
                        curExpanded: any,
                        allExpanded: any,
                        rowsExpanded: any
                      ) => {
                        let expandable = expandableQueueRows.find(
                          (e) => e.row === curExpanded[0].index
                        );

                        let rows = [...expandableQueueRows];
                        if (expandable) {
                          rows = expandableQueueRows.filter(
                            (v) => v.row !== curExpanded[0].index
                          );
                        } else {
                          rows.push({ row: curExpanded[0].index });
                        }

                        setExpandableQueueRows(rows);
                        console.log(expandableQueueRows);
                      },
                    }}
                  />
                </TableCell>
              </TableRow>
            );
          },
          onRowExpansionChange: (
            curExpanded: any,
            allExpanded: any,
            rowsExpanded: any
          ) => {
            let expandable = expandableClientRows.find(
              (e) => e.row === curExpanded[0].index
            );

            let rows = [...expandableClientRows];
            if (expandable) {
              rows = expandableClientRows.filter(
                (v) => v.row !== curExpanded[0].index
              );
            } else {
              rows.push({ row: curExpanded[0].index });
            }

            setExpandableClientRows(rows);
            console.log(expandableClientRows);
          },
        }}
      />
    </QueueArea>
  );
};
