import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import { PrimaryButton } from "../../../components/Buttons/PrimaryButton";
import { SecondaryButton } from "../../../components/Buttons/SecondaryButton";
import { Page } from "../../../components/Page";
import { Table } from "../../../components/Table";
import { useAuth } from "../../../hooks/auth";
import { request } from "../../../services/request";


interface IName {
  name?: string;
}

export const EditableFields: React.FC = () => {
  const history = useHistory();
  const { selectedClient } = useAuth();
  const { campaignId }: { campaignId: string } = useParams();
  const [names, setNames] = useState<IName[]>([]);

  const columns = [
    {
      name: "field",
      label: "Campo",
    },
    {
      name: "_id",
      label: "Ações",
      options: {
        filter: false,
        customBodyRender: (v: any, f: any) => {
          return (
           <Fragment>
              <SecondaryButton onClick={() => handleDelete(f.rowData[0])}>
                Excluir
              </SecondaryButton>
           </Fragment>
          );
        },
      },
    },
  ];

  const getFields = async () => {
    if(!selectedClient) return;

    const response = await request({
      method: "GET",
      path: `campaign/get-fields-campaign/${selectedClient?.value}?campaignId=${campaignId}`,
      showSuccessMessage: false,
    });
    if (!response.error) {
      setNames(
        response.map((f: IName) => {
          return {
            field: f.name
          };
        })
      );
    }
  };

  useEffect(() => {
    getFields();
  }, [selectedClient]);

  const handleDelete = async (field: any) => {

    await request({
      method: 'DELETE',
      path: `campaign/delete-fields-campaign/${selectedClient?.value}?campaignId=${campaignId}`,
      data: {
        field
      },
      showSuccessMessage: true,
      showErrorMessage: true
    })

    getFields();
  };

  return (
    <Page header="Campanhas > Campos editaveis">
      <Fragment>
        <PrimaryButton text="Novo campo" onClick={() => history.push('campos-editaveis/novo')} />
      </Fragment>
      <Fragment>
        <Table columns={columns} title="" data={names} />
      </Fragment>
    </Page>
  );
};
