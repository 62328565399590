import React, { Fragment, useEffect, useState, useRef } from "react";
import { NewButton } from "../../../components/Buttons/PrimaryButton/styles";
import { FormInputText } from "../../../components/FormInputs/Input";
import Select from "react-select";
import CreateatableSelect from "react-select/creatable";
import { useHistory } from "react-router";
import { useAuth } from "../../../hooks/auth";
import { FormArea } from "../../../components/FormInputs/styles";
import { Page } from "../../../components/Page";
import { request } from "../../../services/request";
import { idText } from "typescript";
import sweetAlert from "../../../utils/sweetAlert";

interface ISelect {
  label: string;
  value: string
}

interface ICampaign {
  name: string;
  type: string;
  active: boolean;
  clientId: string;
  queueId: string | undefined,
  group?: {
    key: string,
    _id: string,
  };
  dateInit: Date | string;
  dateEnd: Date | string;
  weight?: number;
}

const maskDate = (value: string) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1/$2")
    .replace(/(\d{2})(\d)/, "$1/$2")
    .replace(/(\d{4})(\d)/, "$1");
};


export const NewCampain: React.FC = () =>  {
  const history = useHistory();
  const queueSelectRef = useRef<any>()
  const { selectedClient } = useAuth();
  const [name, setName] = useState<string>('');
  const [type, setType] = useState<string>('');
  const [dateInit, setDateInit] = useState<string>('');
  const [weight, setweight] = useState<any>('');
  const [dateEnd, setDateEnd] = useState<string>('');
  const [active, setActive] = useState<boolean>(true);
  const [SelectedClient, setSelectedClient] = useState<any>();
  const [SelectedQueue, setSelectedQueue] = useState<ISelect  | undefined>();
  const [SelectedGroup, setSelectedGroup] = useState<any>();
  const [queues, setQueues] = useState<ISelect[]>([]);
  const [camapaignGroups, setCampaignGroups] = useState<ISelect[]>([]);


  const getGroups = async (clientId: string) => {
    const groups = await request({
      method: 'GET',
      path: `campaign/get-campaign-groups/${clientId}`,
      showSuccessMessage: false,
      showErrorMessage: false
    });
    const groupsMap = groups?.map((c: { key: any; _id: any; }) => {
      return {
        label: c.key,
        value: c._id
      }
    });
    setCampaignGroups(groupsMap);
  }

  const getClientInfos = async (clientId: any) => {
    if(!clientId) return null
    const response = await request({
      method: 'GET',
      path: `queues/client/${clientId}`,
      showSuccessMessage: false,
      showErrorMessage: false
    });
    setSelectedClient(response.client)
    const queuesMap = response.queues?.map((c: { name: any; _id: any; }) => {
      return {
        label: c.name,
        value: c._id
      }
    });
    setQueues(queuesMap);

    getGroups(clientId);
  };

  const createCampaignGroup = async (key: string) => {
    await request({
      method: 'POST',
      path: `campaign/create-campaign-group`,
      data: {
        key,
        clientId: SelectedClient?._id
      },
      showSuccessMessage: false,
      showErrorMessage: false
    });

    getGroups(SelectedClient?._id)
  }

  const createCampaign = async () => {
    const data: ICampaign = {
      name,
      active,
      clientId: SelectedClient?._id,
      queueId: SelectedQueue?.value,
      type,
      group: SelectedGroup,
      dateEnd,
      dateInit,
      weight
    };

    let hasEmptyField = false;
    for (const [key, value] of Object.entries(data)) {
      if (value === null || value === undefined) hasEmptyField = true;
    }

    if (hasEmptyField) {
      sweetAlert.error(
       'Preencha todos os campos para salvar'
      );

      return;
    }

    await request({
      method: 'POST',
      path: `campaign/create-campaign`,
      data,
      showSuccessMessage: false,
      showErrorMessage: false
    });

    history.goBack()
  }

  useEffect(() => {
    getClientInfos(selectedClient?.value);
    queueSelectRef.current?.select.setValue(null)
    setSelectedGroup(null)
  },[selectedClient]);
  return (
    <Page header="Campanhas > Nova">
      <Fragment></Fragment>
      <FormArea>
        <Select
          label="Fila"
          name="queue"
          onChange={(v: any) => {
            setSelectedQueue(v)
          }}
          ref={queueSelectRef}
          options={queues}
          value={SelectedQueue}
          placeholder="Fila"
        />
        <FormInputText
          label="Nome"
          name="name"
          onChangeFather={(e: any) => setName(e)}
          width="100%"
          value={name}
        />
        <FormInputText
          label="Tipo de Campanha"
          name="campaign-type"
          onChangeFather={(v: any) => {
            setType(v)
          }}
          width="100%"
          value={type}
        />
        <FormInputText
          label="Data de Início"
          name="initial-date"
          onChangeFather={(v: any) => {
            setDateInit(maskDate(v))
          }}
          width="100%"
          defaultValue={dateInit}
        />
        <FormInputText
          label="Data do fim"
          name="end-date"
          onChangeFather={(v: any) => {
            setDateEnd(maskDate(v))
          }}
          defaultValue={dateEnd}
          width="100%"
        />
        <FormInputText
          label="Peso"
          type={'number'}
          name="weight"
          onChangeFather={(v: any) => {
            const n = parseInt(v)
            if(!v) {
              setweight('')
            }
            if(n >= 0 && n <= 100) {
              setweight(n);
            }
          }}
          placeholder='0 a 100'
          defaultValue={weight}
          width="100%"
        />
        <CreateatableSelect
          label="Grupo de campanha"
          name="campaignGroups"
          options={camapaignGroups}
          onCreateOption={(v: any) => {
            createCampaignGroup(v)
          }}
          onChange={(v: any) => {
            setSelectedGroup({
              key: v?.label,
              _id: v?.value
            })
          }}
          value={ SelectedGroup? {
            label: SelectedGroup?.key,
            value: SelectedGroup?._id
          } : null}
          placeholder="Grupo de campanha"
        />
        <Select
          label="Ativo"
          name="active"
          onChange={(v: any) => {
            setActive(v?.value)
          }}
          options={[
            { label: "Sim", value: true },
            { label: "Não", value: false },
          ]}
          placeholder="Ativo"
        />
      </FormArea>

      <NewButton onClick={() => createCampaign()}>Salvar</NewButton>
    </Page>
  );
}
