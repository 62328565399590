import React, { Fragment, useEffect, useState } from "react";
import { NewButton } from "../../../components/Buttons/PrimaryButton/styles";
import { FormInputText } from "../../../components/FormInputs/Input";
import Select from "react-select";
import CreateatableSelect from "react-select/creatable";
import { useHistory } from "react-router";
import { FormArea } from "../../../components/FormInputs/styles";
import { Page } from "../../../components/Page";
import { request } from "../../../services/request";
import { useParams } from "react-router-dom";
import { InputDate, LabelDate, BoxDate } from "../styled";

interface ISelect {
  label: string;
  value: string
}


export const EditScheduling: React.FC = () =>  {
  const history = useHistory();
  const [attendants, setAttendants] = useState<any[]>([]);
  const [attendant, setAttendant] = useState<ISelect>();
  const { scheduleId }: { scheduleId: string } = useParams();
  const [scheduleDate, setScheduleDate] = useState<string>('');
  const [scheduleHour, setScheduleHour] = useState<string>('');

  const getSchedule= async () => {
    try {
     const response = await request({
       method: 'GET',
       path: `schedules/get-schedule/${scheduleId}`,
       showSuccessMessage: false,
       showErrorMessage: false
     })
     setAttendant({
      label: response[0]?.userId.name,
      value: response[0]?.userId._id
     })

     setScheduleDate(response[0].scheduleDate)
     setScheduleHour(response[0].scheduleHour)
     } catch(e) {
       console.log(e)
     }
   }

  const getAttendants = async () => {
    try {
     const response = await request({
       method: 'GET',
       path: `users/get-attendants`,
       showSuccessMessage: false,
       showErrorMessage: false
     })
     console.log(response)
     const data = response.map((a: any) => {
      return {
        label: a.name,
        value: a._id
      }
     });

     setAttendants(data);
     } catch(e) {
       console.log(e)
     }
   }

  const saveChanges = async () => {
    await request({
      method: 'PUT',
      path: `schedules/update-schedule/${scheduleId}`,
      data: {
        userId: attendant?.value,
        scheduleDate,
        scheduleHour,
      },
      showSuccessMessage: true,
      showErrorMessage: true,
    })
    history.goBack()
  }

  useEffect(()=> {
    getAttendants();
    getSchedule();
  }, [])

  return (
    <Page header="Agendamentos > Editar">
      <Fragment></Fragment>
      <FormArea>
        <BoxDate>
          <LabelDate>Horário:</LabelDate>
          <InputDate type={'datetime-local'} value={`${scheduleDate} ${scheduleHour}`} onChange={(e) => {
            const date = new Date(e?.target?.value)
            setScheduleDate(date.toISOString().slice(0, 10))
            setScheduleHour(date.toLocaleTimeString('default', {
              hour: '2-digit',
              minute: '2-digit',
            }))
          }}/>
        </BoxDate>
        <Select
          label="Atendente"
          name="attendant"
          onChange={(v: any) => {
            setAttendant(v)
          }}
          options={attendants}
          placeholder="Atendente"
          value={attendant}
        />
      </FormArea>

      <NewButton onClick={saveChanges}>Salvar</NewButton>
    </Page>
  );
}
