import React, { Fragment, useState, useEffect } from "react";
import { Table } from "../../../components/Table";
import { PrimaryButton } from "../../../components/Buttons/PrimaryButton";
import { Page } from "../../../components/Page";
import { useAuth } from "../../../hooks/auth";
import { useHistory } from "react-router";
import { request } from "../../../services/request";
import { useParams } from "react-router-dom";
import { SecondaryButton } from "../../../components/Buttons/SecondaryButton";


export const ListFields: React.FC = () => {
  const history = useHistory();
  const [fields, setFields] = useState<Array<any>>([])
  const { status }: { status: string } = useParams();
  const { selectedClient } = useAuth();
  const statusTypes = [ 'preditivo', 'receptivo', 'preview']

  const getFields = async () => {
    if(!selectedClient?.value) return null
     const response = await request({
      method: 'GET',
      path: `fields/get-fields?clientId=${selectedClient?.value}&status=${status}`,
      showSuccessMessage: false,
      showErrorMessage: true
    });
    setFields(response)
  }

  const columns = [
    {
      name: "name",
      label: "Nome",
    },
    {
      name: "type",
      label: "Tipo",
    },
    {
      name: "name",
      label: "Ações",
      options: {
        filter: false,
        customBodyRender: (v: any) => {
          return (
            <Fragment>
              <SecondaryButton onClick={() => history.push(`/campos/editar?name=${v}&status=${status}`)}>
                editar
              </SecondaryButton>
            </Fragment>
          );
        },
      },
    },
  ]

  useEffect(() => {
    if (!statusTypes.includes(status)) {
      history.goBack()
    }
    getFields()
  }, [selectedClient])
  return (
    <Page header={`Campos variáveis > ${status} > Listagem`}>
      <Fragment>
        <PrimaryButton text="Novo campo" onClick={() => history.push('novo')} />
      </Fragment>
      <Fragment>
        <Table columns={columns} title="" data={fields}/>
      </Fragment>
    </Page>
  );
}
