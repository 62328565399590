import { Fragment, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { PrimaryButton } from "../../components/Buttons/PrimaryButton";
import { SecondaryButton } from "../../components/Buttons/SecondaryButton";
import { Page } from "../../components/Page";
import { Table } from "../../components/Table";
import { useHeader } from "../../hooks/headerHook";
import { request } from "../../services/request";
import { NewQueue } from "./New";

interface IQueue {
  _id: string;
  name: string;
  active: boolean;
  enterpriseId: string;
  clientId: string;
  queueInAsterisk: string;
  activeString?: string;
}

export const Queues: React.FC = () => {
  const [queues, setQueues] = useState<Array<IQueue>>([]);
  const [clientName, setClientName] = useState<string>("");
  const { clientId }: { clientId: string } = useParams();
  const [newQueueClickButton, setNewQueueClickButton] =
    useState<Boolean>(false);
  const { setHeader } = useHeader();
  const history = useHistory();

  const columns = [
    {
      name: "name",
      label: "Nome",
    },
    {
      name: "activeString",
      label: "Ativo",
    },
    {
      name: "_id",
      label: "Ações",
      options: {
        filter: false,
        customBodyRender: (v: any) => {
          return (
            <Fragment>
              <SecondaryButton onClick={() => gotToPage(`filas/${v}/leads`)}>
                leads
              </SecondaryButton>

              <SecondaryButton
                onClick={() =>
                  gotToPage(`filas/${v}/adicionar-remover-usuarios`)
                }
              >
                adicionar/remover usúarios
              </SecondaryButton>
            </Fragment>
          );
        },
      },
    },
  ];

  const gotToPage = (route: string) => {
    history.push(route);
  };

  const getQueues = async () => {
    const response = await request({
      method: "GET",
      path: `queues/client/${clientId}`,
      showSuccessMessage: false,
    });

    if (!response.error) {
      setClientName(response.client.name);
      setQueues(
        response.queues.map((u: IQueue) => {
          u.activeString = u.active ? "Sim" : "Não";
          return u;
        })
      );
    }
  };

  const clickNewQueueOrBack = () => {
    setNewQueueClickButton(!newQueueClickButton);
    setHeader(newQueueClickButton ? `Filas - ${clientName}` : "Nova fila");
    if (newQueueClickButton) getQueues();
  };

  useEffect(() => {
    getQueues();
  }, []); //eslint-disable-line

  return (
    <Page header={`Clientes > ${clientName} > Filas`}>
      <PrimaryButton
        text={!newQueueClickButton ? "+ nova fila" : "< voltar"}
        onClick={clickNewQueueOrBack}
      />
      <Fragment>
        {!newQueueClickButton && (
          <Table columns={columns} title="" data={queues} />
        )}
        {newQueueClickButton && (
          <NewQueue clickNewQueueOrBack={clickNewQueueOrBack} />
        )}
      </Fragment>
    </Page>
  );
};
