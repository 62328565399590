import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: Wrap;
  justify-content: space-between;
  margin: 32px 0 16px;
  width: 100%;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 16px;
  width: 45%;

  & label {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 500;
  }

  & input {
    border: 1px solid black;
    background-color: transparent;
    font-size: 16px;
    padding: 5px 8px;
    border-radius: 10px;
    height: 28px;
  }
`;
