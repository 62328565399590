import { useEffect, useState, Fragment } from "react";
import {
  FaChartLine,
  FaDollarSign,
  FaPhone,
  FaStopCircle,
  FaUsers,
  FaChartBar,
  FaRegCalendarAlt,
  FaFont
} from "react-icons/fa";
import { IconType } from "react-icons/lib";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../hooks/auth";
import Select from "react-select";
import { clearLs } from "../../localStorage";
import { ExitButton, Link, Menu, NavArea, ProfileArea } from "./styles";
import { request } from "../../services/request";

interface IMenu {
  name: string;
  title: string;
  path: string;
  icon: IconType;
  active?: boolean;
}

interface ISideBar {
  setMenuTitle: Function;
}

export const SideBar: React.FC<ISideBar> = ({ setMenuTitle }) => {
  const [menu, setMenu] = useState<Array<IMenu>>([
    {
      icon: FaChartLine,
      name: "Dashboard",
      path: "/",
      title: "Dashboard",
    },
    {
      icon: FaUsers,
      name: "Usuários",
      path: "/usuarios",
      title: "Usuários",
    },
    {
      icon: FaFont,
      name: "Campos variáveis",
      path: "/campos",
      title: "Campos variáveis",
    },
    {
      icon: FaDollarSign,
      name: "Clientes",
      path: "/clientes",
      title: "Clientes",
    },
    {
      icon: FaPhone,
      name: "Ramais",
      path: "/ramais",
      title: "Ramais",
    },
    {
      icon: FaStopCircle,
      name: "Pausas",
      path: "/pausas",
      title: "Pausas",
    },
    {
      icon: FaStopCircle,
      name: "Tabulações pré abordagem",
      path: "/tabulacoes-pre-abordagem",
      title: "Tabulações pré abordagem",
    },

    {
      icon: FaChartBar,
      name: "Campanhas",
      path: "/campaigns",
      title: "Campanhas",
    },
    {
      icon: FaRegCalendarAlt,
      name: "Agendamentos",
      path: "/agendamentos",
      title: "Agendamentos",
    },
  ]);

  const { getPayload, clients, setSelectedClient, selectedClient } = useAuth();
  const route = useHistory();
  const changeTitleHead = (title: string) => {
    let defaultTitle = "Discador";
    const titleEl = document.querySelector("title");
    if (titleEl) {
      titleEl.text = `${defaultTitle} - ${title}`;
    }

    setMenuTitle(title);
  };

  const handleExitButton = () => {
    clearLs();
    route.push("/login");
  };

  useEffect(() => {
    let newMenu: Array<IMenu> = [];
    let title = "";
    menu.forEach((m) => {
      if (route.location.pathname === "/" && m.path === "/") {
        m.active = true;
        title = m.title;
      } else if (
        route.location.pathname.indexOf(m.path) > -1 &&
        m.path !== "/"
      ) {
        m.active = true;
        title = m.title;
      } else {
        m.active = false;
      }
      newMenu.push(m);
    });
    setMenu(newMenu);
    changeTitleHead(title);
  }, [route.location.pathname]); // eslint-disable-line

  const customStyles = {
    menu: (provided: any, state: any) => ({
      ...provided,
      width: state.selectProps.width,
      backgroundColor: '#fff',
      color: state.selectProps.menuColor,
      padding: 0,
    }),

    input: (provided: any, state: any) => ({
      ...provided,
      color: '#fff',
    }),

    container: (provided: any, state: any) => ({
      ...provided,
      marginTop: 20,
      width: state.selectProps.width,
    }),
  }

  return (
    <NavArea>
      <ProfileArea>
        <p>{getPayload().name}</p>
        <Fragment>
        <Select
          label="Cliente"
          name="client"
          width='150px'
          menuColor='black'
          onChange={(v: any) => {
            setSelectedClient(v)
          }}
          styles={customStyles}
          value={selectedClient}
          options={clients}
          placeholder="Cliente"
        />
        </Fragment>
      </ProfileArea>
      <Menu>
        {menu.map((menu) => {
          const Icon = menu.icon;
          return (
            <Link
              to={menu.path}
              linkactive={menu.active ? "true" : "false"}
              key={menu.path}
            >
              <li key={menu.title}>
                <Icon />
                <span>{menu.title}</span>
              </li>
            </Link>
          );
        })}
      </Menu>
      <ExitButton onClick={handleExitButton}>Sair</ExitButton>
    </NavArea>
  );
};
