import React, { createContext, useContext, useEffect, useState } from "react";
import { getLsUserToken } from "../localStorage";
import { request } from "../services/request";

interface IUser {
  _id: string;
  name: string;
  ramalId: string;
  userType: {
    name: string;
  };
}

interface IClient {
  label: string;
  value: string
}
interface AuthContextData {
  user: IUser;
  clients: IClient[];
  selectedClient: IClient | undefined;
  setSelectedClient: Function;
  verifyUserExists: () => Promise<void>;
  getPayload: () => IUser;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<any>({});
  const [clients, setClients] = useState<IClient[]>([]);
  const [selectedClient, setSelectedClient] = useState<IClient>();

  function parseJwt(token: string) {
    const tokenOnly = token.split(" ")[1];
    var base64Url = tokenOnly.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  const getClients = async () => {
    const response = await request({
      method: 'GET',
      path: 'clients',
      showSuccessMessage: false,
      showErrorMessage: false
    })
    const clientsMap: IClient[] = response.map((c: { name: any; _id: any; }) => {
      return {
        label: c.name,
        value: c._id
      }
    })
    setClients(clientsMap)
    console.log(clientsMap[0])
    setSelectedClient(clientsMap[0])
    return clientsMap
  };
  const getPayload = () => {
    const token = getLsUserToken();
    if (!token) {
      return {};
    }

    return parseJwt(token);
  };

  const verifyUserExists = async () => {
    const user = getPayload();
    if (user._id) {
      setUser(user);
      getClients()
    }
  };
  useEffect(() => {
    verifyUserExists();
    // eslint-disable-next-line
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user: user,
        clients,
        selectedClient,
        setSelectedClient: setSelectedClient,
        verifyUserExists: verifyUserExists,
        getPayload: getPayload,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}
