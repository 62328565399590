import { Fragment, useEffect, useState } from "react";
import { NewButton } from "../../../components/Buttons/PrimaryButton/styles";
import { FormInputText } from "../../../components/FormInputs/Input";
import { FormArea } from "../../../components/FormInputs/styles";
import { Page } from "../../../components/Page";
import { request } from "../../../services/request";
import sweetAlert from "../../../utils/sweetAlert";

export const NewClient: React.FC = () => {
  const [client, setClient] = useState<{ [key: string]: any }>({});

  const handleSave = async () => {
    if (!client.name) {
      sweetAlert.error("Nome do cliente é obrigatório");
      return;
    }

    await request({
      loadingMessage: "Criando cliente e enviando arquivos, aguarde...",
      timeout: 999999999,
      method: "POST",
      path: `clients`,
      data: client,
    });
  };

  const handleOnChangeInput = async (value: any, key: string) => {
    setClient((oldClient) => {
      return {
        ...oldClient,
        [key]: value,
      };
    });
  };

  useEffect(() => {
    // getGuests();
  }, []); //eslint-disable-line

  return (
    <Page header="Cliente > Novo">
      <Fragment></Fragment>
      <FormArea>
        <FormInputText
          label="Nome"
          name="name"
          onChangeFather={handleOnChangeInput}
          width="100%"
          defaultValue={client.name ? client.name : ""}
        />
      </FormArea>

      <NewButton onClick={handleSave}>salvar</NewButton>
    </Page>
  );
};
