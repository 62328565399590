import { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { PrimaryButton } from '../../../components/Buttons/PrimaryButton';
import { SecondaryButton } from "../../../components/Buttons/SecondaryButton";
import { Page } from "../../../components/Page";
import { Table } from "../../../components/Table";
import { request } from "../../../services/request";
import { useAuth } from "../../../hooks/auth";
import { useParams } from "react-router-dom";

interface IFieldsData {
  attendant: string;
  client: string;
  phone: string;
  _id: string;
}

export const Records: React.FC = () => {
  const { selectedClient } = useAuth();
  const { status }: { status: string } = useParams();
  const [records, setRecords] = useState<Array<IFieldsData>>([]);

  const columns = [
    {
      name: "client",
      label: "Cliente",
    },
    {
      name: "phone",
      label: "Telefone",
    },
    {
      name: "attendant",
      label: "Atendente"
    },
    {
      name: "_id",
      label: "Ações",
      options: {
        filter: false,
        customBodyRender: (v: any) => {
          return (
            <SecondaryButton onClick={() => gotToPage(`registros/${v}/dados-preenchidos`)}>
              visualizar
            </SecondaryButton>
          );
        },
      },
    },
  ];
  const history = useHistory();

  const gotToPage = (route: string) => {
    history.push(route);
  };

  const getFieldsData = async () => {
    if(!selectedClient) return;

    const response = await request({
      method: "GET",
      path: `fields/get-all-fields-data/${selectedClient?.value}?status=${status}`,
      showSuccessMessage: false,
    });
    if (!response.error) {
      setRecords(
        response.map((f: IFieldsData) => {
          return {
            client: f.client,
            phone: f.phone,
            attendant: f.attendant,
            _id: f._id,
          };
        })
      );
    }
  };

  useEffect(() => {
    getFieldsData();
  }, [selectedClient]);

  return (
    <Page header="Registros">
      <Fragment>
      </Fragment>
      <Fragment>
        <Table columns={columns} title="" data={records} />
      </Fragment>
    </Page>
  );
};
