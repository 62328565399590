import React, { Fragment, useState, useEffect } from "react";
import { Table } from "../../../components/Table";
import { Page } from "../../../components/Page";
import { useAuth } from "../../../hooks/auth";
import { useHistory } from "react-router";
import { request } from "../../../services/request";
import { SecondaryButton } from "../../../components/Buttons/SecondaryButton";


export const CampaignsGroup: React.FC = () => {
  const history = useHistory();
  const { selectedClient } = useAuth();
  const [groups, setGroups] = useState<any>([])
  const getGroups = async (clientId: any) => {
    const groups = await request({
      method: 'GET',
      path: `campaign/get-campaign-groups/${clientId}`,
      showSuccessMessage: false,
      showErrorMessage: false
    });
    setGroups(groups);
  }

  useEffect(() => {
    getGroups(selectedClient?.value)
  }, [selectedClient])


  const columns = [
    {
      name: "key",
      label: "Nome",
    },
    {
      name: "_id",
      label: "Ações",
      options: {
        filter: false,
        customBodyRender: (v: any) => {
          return (
            <SecondaryButton onClick={() => history.push(`grupos/${v}/editar`)}>
              Gerir campanhas
            </SecondaryButton>
          );
        },
      },
    },
  ]

  return (
    <Page header="Campanhas > Grupos">
      <Fragment></Fragment>
      <Fragment>
        <Table columns={columns} title="" data={groups}/>
      </Fragment>
    </Page>
  );
}
