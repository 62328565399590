import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router";
import { PrimaryButton } from "../../../components/Buttons/PrimaryButton";
import { Page } from "../../../components/Page";
import { AreaButtons } from "../../../components/Page/styles";
import { Table } from "../../../components/Table";
import { request } from "../../../services/request";
import sweetAlert from "../../../utils/sweetAlert";

let LeadsEventsBr: any = {
  VIRGIN: "Virgem",
  REPROCESSED: "Reprocessado",
  DUPLICATED_LEAD: "Duplicado",
  FINALIZED: "Finalizado",
  SEND_TO_PROCESS: "Processando",
  INVALID_TELEPHONE: "Telefone inválido",
  DUPLICATED_TELEPHONE: "Telefone duplicado",
  CONTROL_ID_INVALID: "ControlID inválido",
  SEND_TO_DIAL: "Pronto para discar",
  WEBHOOK_RECEIVED: "Ligação feita",
  TABBING_STATUS: "Tabulado",
  SHOW_IN_USER_INTERFACE: "Em ligação",
  ERROR_TO_SEND_DIAL_API: "Erro ao enviar para os discador",
};

interface IQueue {
  _id: string;
  name: string;
  active: boolean;
  enterpriseId: string;
  clientId: string;
  queueInAsterisk: string;
  activeString?: string;
}

export const Leads: React.FC = () => {
  const [leads, setLeads] = useState<Array<IQueue>>([]);
  const [clientName] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const [totalPerPage, setTotalPerPage] = useState<number>(10);
  const { queueId }: { queueId: string } = useParams();

  const columns = [
    {
      name: "name",
      label: "Nome",
    },
    {
      name: "telsFormated",
      label: "Telefones",
      options: {
        filter: true,
        customBodyRender: (v: any) => {
          return (
            <Fragment>
              {v.map((t: any) => {
                return <p>{t}</p>;
              })}
            </Fragment>
          );
        },
      },
    },
  ];

  const options = {
    onChangePage: (page: number) => {
      setPage(page);
    },
    onChangeRowsPerPage: (total: number) => {
      console.log(total);
      setTotalPerPage(total);
      getLeads(total);
    },
    serverSide: true,
    searchable: false,
    filter: false,
    count,
  };

  const reprocessLeads = async () => {
    if (
      !(await sweetAlert.confirm(
        "Tem certeza que quer reprocessar a fila?",
        "Sim",
        "Não"
      ))
    )
      return;

    const response = await request({
      method: "GET",
      path: `leads/reprocess/${queueId}`,
      showSuccessMessage: true,
      timeout: 99999,
    });

    if (!response.error) {
      getLeads();
    }
  };

  const cleanQueue = async () => {
    if (
      !(await sweetAlert.confirm(
        "Tem certeza que quer limpar a fila? Todos os leads serão deletados",
        "Sim",
        "Não"
      ))
    )
      return;

    const response = await request({
      method: "GET",
      path: `leads/clean/${queueId}`,
      showSuccessMessage: true,
      timeout: 99999,
    });

    if (!response.error) {
      getLeads();
    }
  };

  useEffect(() => {
    getLeads(totalPerPage);
  }, [page]); //eslint-disable-line

  const getLeads = async (total?: number) => {
    const response = await request({
      method: "GET",
      path: `leads/${queueId}?page=${page}&total=${
        total ? total : totalPerPage
      }`,
      showSuccessMessage: false,
      timeout: 99999,
    });

    if (!response.error) {
      setCount(response.total);
      setLeads(
        response.leads.map((r: any) => {
          let tels = r.telephones.map((t: any) => {
            return `${t.telephone} - ${
              LeadsEventsBr[t.events[t.events.length - 1].event]
            }`;
          });
          return {
            ...r,
            telsFormated: tels,
          };
        })
      );
    }
  };

  return (
    <Page header={`Clientes > ${clientName} > Filas`}>
      <AreaButtons>
        <PrimaryButton text="Ressetar fila" onClick={reprocessLeads} />
        <PrimaryButton text="Limpar fila" onClick={cleanQueue} />
      </AreaButtons>

      <Fragment>
        <Table columns={columns} title="" data={leads} moreOptions={options} />
      </Fragment>
    </Page>
  );
};
