import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router";
import { NewButton } from "../../../components/Buttons/PrimaryButton/styles";
import { Page } from "../../../components/Page";
import { request } from "../../../services/request";
import { useStateRef } from "../../../utils";
import { User, UsersArea, UsersContainer } from "./styles";

interface IUser {
  name: string;
  login: string;
  _id: string;
}

export const AddRemoveUsersInQueue: React.FC = () => {
  const [users, setUsers, usersRef] = useStateRef<Array<IUser>>([]);
  const [usersInQueue, setUsersInQueue, usersInQueueRef] = useStateRef<
    Array<IUser>
  >([]);
  const [clientName, setClientName] = useState<string>("");
  const [queueName, setQueueName] = useState<string>("");

  const { clientId, queueId } = useParams<{
    clientId: string;
    queueId: string;
  }>();

  const getUsers = async () => {
    const queueUsersResponse = await request({
      method: "GET",
      path: `queues/client/${clientId}/queue/${queueId}`,
      showSuccessMessage: false,
    });

    const allUsersResponse = await request({
      method: "GET",
      path: `users`,
      showSuccessMessage: false,
    });

    if (!queueUsersResponse.error && !allUsersResponse.error) {
      let allUsers: Array<IUser> = allUsersResponse;
      let queueUsers: Array<IUser> = queueUsersResponse.queue.users
        .filter((qU: any) => {
          return allUsers.find((au) => au._id === qU);
        })
        .map((qU: string) => {
          let find = allUsers.find((au) => au._id === qU);

          return find;
        });

      setUsersInQueue(queueUsers);
      setUsers(normalizeUsers(allUsers, queueUsers));

      setClientName(queueUsersResponse.client.name);
      setQueueName(queueUsersResponse.queue.name);
    }
  };

  const normalizeUsers = (allUsers: Array<IUser>, queueUsers: Array<IUser>) => {
    return allUsers.filter(
      (u) =>
        !queueUsers.find((ui) => {
          if (!ui) return false;
          return ui.login === u.login;
        })
    );
  };

  const handleSave = async () => {
    await request({
      method: "PUT",
      path: `queues/client/${clientId}/queue/${queueId}/update-users-queue`,
      data: { users: usersInQueue.filter((u) => u).map((u) => u._id) },
      timeout: 99999,
      loadingMessage:
        "Atualizando fila, esse processo pode demorar.",
    });
  };

  const handleAddRemoveUsuerQueue = (
    type: "add" | "remove",
    login: string,
    name: string,
    _id: string
  ) => {
    if (type === "add") {
      setUsersInQueue((oldUsersInQueue: Array<IUser>) => [
        ...oldUsersInQueue,
        { login, name, _id },
      ]);
      setUsers(normalizeUsers(usersRef.current, usersInQueueRef.current));
      return;
    }

    if (type === "remove") {
      let oldUsers = usersInQueue;

      let findUser = usersInQueue.findIndex((oU: IUser) => oU.login === login);
      oldUsers.splice(findUser, 1);

      setUsersInQueue(oldUsers);
      setUsers((oUse: Array<IUser>) => [{ login, name, _id }, ...oUse]);
    }
  };

  useEffect(() => {
    getUsers();
  }, []); //eslint-disable-line

  return (
    <Page header={`Cliente > ${clientName} > Fila > ${queueName}`}>
      <Fragment></Fragment>
      <UsersContainer>
        <UsersArea>
          <p>Usúarios que não estão na fila ({users.length})</p>
          {users
            .filter((u) => u)
            .map((u) => (
              <User
                key={u.login}
                onClick={() =>
                  handleAddRemoveUsuerQueue("add", u.login, u.name, u._id)
                }
              >
                {u.name} - {u.login}
              </User>
            ))}
        </UsersArea>
        <UsersArea>
          <p>Usúarios na fila ({usersInQueue.length})</p>
          {usersInQueue
            .filter((u) => u)
            .map((u, i) => {
              return (
                <User
                  key={u.login}
                  onClick={() =>
                    handleAddRemoveUsuerQueue("remove", u.login, u.name, u._id)
                  }
                >
                  {u.name} - {u.login}
                </User>
              );
            })}
        </UsersArea>
      </UsersContainer>

      <NewButton onClick={handleSave}>salvar</NewButton>
    </Page>
  );
};
