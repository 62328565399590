import React, { Fragment, useEffect, useState } from "react";
import { NewButton } from "../../../components/Buttons/PrimaryButton/styles";
import { useHistory } from "react-router";
import { FormArea } from "../../../components/FormInputs/styles";
import { Page } from "../../../components/Page";
import { request } from "../../../services/request";
import { User, UsersArea, UsersContainer } from "./styles";
import { useParams } from "react-router-dom";

interface IGroup {
  key: string;
  campaigns: [{
    name: string;
    _id: string;
    queueId: { name: any; };
  }] | any;
}

export const EditGroupCampaign: React.FC = () =>  {
  const history = useHistory();
  const [group, setGroup] = useState<IGroup>({
    key: '',
    campaigns: []
  });
  const [campaignsNotInGroup, setCampaignsNotInGroup ] = useState<{_id: any; name: any; queueId: { name: any; }; }[]>([])
  const { groupId }: { groupId: string } = useParams();
  const saveChanges = async () => {
    await request({
      method: 'PUT',
      path: `campaign/update-campaign-group`,
      data: group,
      showSuccessMessage: false,
      showErrorMessage: true
    });

    history.goBack()
  }

  const getGroup = async () => {
    const result = await request({
      method: 'GET',
      path: `campaign/get-campaign-group/${groupId}`,
      showSuccessMessage: false,
      showErrorMessage: false
    });
    const campaignsWithoutGroup = result?.campaignWithoutGroup.map((c: {
      [x: string]: any; name: any; queueId: { name: any; _id: any };
}) => {
      return {
        name: c.name,
        _id: c._id,
        queue: c.queueId.name,
      }
    })

    const campaigns = result?.group?.campaigns.map((c: {
      _id: any; name: any; queueId: { name: any; };
}) => {
      return {
        name: c.name,
        _id: c._id,
        queue: c.queueId.name,
      }
    })

    setCampaignsNotInGroup(campaignsWithoutGroup)
    setGroup({...result?.group, campaigns});
  }

  useEffect(()=> {
    getGroup()
  }, [])

  return (
    <Page header={`Campanhas > Grupos > ${group?.key}`}>
      <Fragment></Fragment>
      <FormArea>
         <UsersContainer>
        <UsersArea>
          <p>Campanhas que não possuem grupo ({campaignsNotInGroup?.length})</p>
          {campaignsNotInGroup?.map((c: any) => (
              <User
                key={c.name}
                onClick={() =>{
                  const update = campaignsNotInGroup.filter(x => {
                    return x._id !== c._id
                  })
                  setCampaignsNotInGroup(update)
                  setGroup({...group, campaigns: [...group?.campaigns, c] })
                  }
                }
              >
                {c.name} - {c.queue}
              </User>
            ))}
        </UsersArea>
        <UsersArea>
          <p>Campanhas no grupo ({group?.campaigns?.length})</p>
          {group?.campaigns
            .map((u: any) => (
              <User
                key={u.login}
                onClick={() =>{
                  const update = group?.campaigns.filter((x: any) => {
                    return x._id !== u._id
                  })
                  setCampaignsNotInGroup([...campaignsNotInGroup, u])
                  setGroup({...group, campaigns: update });
                  }
                }
              >
                {u.name} - {u.queue}
              </User>
            ))}
        </UsersArea>
      </UsersContainer>
      </FormArea>

      <NewButton onClick={saveChanges}>Salvar</NewButton>
    </Page>
  );
}
