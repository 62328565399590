import styled from "styled-components";

export const Avatar = styled.img`
  width: 80px;
  border-radius: 8px;
`;

export const ModalArea = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background: #0000007d;
  z-index: 999;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
`;

export const ModalBody = styled.div`
  padding: 20px;
  width: 400px;
  background: #fff;
  border-radius: 8px;
  position: relative;
  border: none;

  div[class*="container"] {
    margin-top: 20px;
  }

  button {
    margin-top: 10px;
    padding: 10px;
  }

  button:last-child {
    float: right;
  }
`;

export const CloseModal = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  background: #1f0533;
  border: none;
  color: #fff;
  border-bottom-left-radius: 6px;
  padding: 8px;
  font-size: 12px;
  font-weight: 600;
`;
